// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Col, Form, Row, Table} from "antd";
import "moment/locale/ru";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableSelectCell from "../../../components/EditableCells/EditableSelectCell";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const openHoursTypes = [{ value: true, name: "да" }, { value: false, name: "нет" }];

class Priorities extends Component {
  constructor(props) {
    super(props);
  };

  render() {
    const {
      customerId,
      priorities,
      onChangeResponseTime,
      onChangeUseOpenHours,
      entityTicketPriorities,
      onChangeEntitiesForEntityTicketPriorities,
      onChangeResponseTimeForEntityTicketPriorities,
      onChangeUseOpenHoursForEntityTicketPriorities,
    } = this.props;

    const columns = [
      {
        title: "Уровень",
        dataIndex: "name",
        width: "50%",
        align: "left"
      },
      {
        title: "Продолжительность, часы",
        dataIndex: "response_time",
        render: (response_time, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={response_time}
              precision={0}
              onChange={onChangeResponseTime}
            />
          );
        },
        width: "30%",
        align: "right"
      },
      {
        title: "Использовать расписание",
        dataIndex: "use_open_hours",
        render: (use_open_hours, record, index) => {
          return (
            <EditableSelectCell
              recordId={record.id}
              items={openHoursTypes}
              value={use_open_hours}
              onChange={onChangeUseOpenHours}
            />
          );
        },
        width: "20%",
        align: "center"
      },
    ];

    const columnsForEntityTicketPriorities = [
      {
        title: "Уровень",
        dataIndex: "name",
        width: "50%",
        align: "left"
      },
      {
        title: "Продолжительность, часы",
        dataIndex: "response_time",
        render: (response_time, record, idx) => {
          return (
            <EditableInputCell
              recordId={idx}
              value={response_time}
              precision={0}
              onChange={onChangeResponseTimeForEntityTicketPriorities}
            />
          );
        },
        width: "30%",
        align: "right"
      },
      {
        title: "Использовать расписание",
        dataIndex: "use_open_hours",
        render: (use_open_hours, record, idx) => {
          return (
            <EditableSelectCell
              recordId={idx}
              items={openHoursTypes}
              value={use_open_hours}
              onChange={onChangeUseOpenHoursForEntityTicketPriorities}
            />
          );
        },
        width: "20%",
        align: "center"
      },
    ];


    return (
      <>
        <Row style={{ paddingBottom: 40 }}>
          <Col>
            <Table
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={priorities}
              rowKey="id"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              style={{ paddingBottom: 0 }}
              label={'Приоритет для объектов'}>
              <SelectMultiFetch
                showSearch
                placeholder="Объекты"
                onChange={onChangeEntitiesForEntityTicketPriorities}
                selected={entityTicketPriorities.entities}
                url={`/entities/search/?customer_id=${customerId}`}
              />
            </Form.Item>
            <Table
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columnsForEntityTicketPriorities}
              dataSource={entityTicketPriorities.ticket_priorities}
              rowKey="sort"
            />
          </Col>
        </Row>
      </>
    );
  }
}

Priorities.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object)
};

export default Priorities;
