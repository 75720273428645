// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, Tabs } from "antd";
import Address from "./Address";
import Details from "./Details";
import Priorities from "./Priorities";
import Settings from "./Settings";
import Timings from "./Timings";

const FormItem = Form.Item;
const { TabPane } = Tabs;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }

  callback = (key) => {
    console.log(key);
  };

  render() {
    const {
      isNew,
      customer,
      getFieldDecorator,
      onChangeName,
      onChangeWebsite,
      onChangePostalCode,
      onChangeCity,
      onChangeStreet,
      onChangeBuilding,
      // details
      onChangeInn,
      onChangeKpp,
      onChangeOgrn,
      // priorities
      onChangeResponseTime,
      onChangeUseOpenHours,
      entityTicketPriorities,
      onChangeEntitiesForEntityTicketPriorities,
      onChangeResponseTimeForEntityTicketPriorities,
      onChangeUseOpenHoursForEntityTicketPriorities,
      // timings
      timings,
      onCreateTiming,
      onEnableTiming,
      onChangeTimingFromState,
      onChangeTimingToState,
      onChangeTimingPriority,
      onChangeTimingEntity,
      onChangeTimingDurationInMinutes,
      onDeleteTiming,
      // settings
      onChangeTicketLogs,
      onChangeTaskLogs,
      onChangeIndoundEmails,
      onChangeInboundDomain,
      onChangeInboundDomainTest,
      onChangeInboundEmail,
      onChangeInboundEmailTest,
      onChangeInboundEmailMember,
      onChangeUseAutoRate,
      onChangeAllowAllOrderTasks,
      onChangeCompletionCommentRequired,
    } = this.props;

    return (
      <Tabs defaultActiveKey="1" onChange={this.callback}>
        <TabPane tab="Наименование и адрес" key="1">
          <Address
            name={customer.name}
            website={customer.website}
            postal_code={customer.postal_code}
            city={customer.city}
            street={customer.street}
            building={customer.building}
            getFieldDecorator={getFieldDecorator}
            onChangeName={onChangeName}
            onChangeWebsite={onChangeWebsite}
            onChangePostalCode={onChangePostalCode}
            onChangeCity={onChangeCity}
            onChangeStreet={onChangeStreet}
            onChangeBuilding={onChangeBuilding}
          />
        </TabPane>
        <TabPane tab="Реквизиты" key="2">
          <Details
            details={customer.details}
            getFieldDecorator={getFieldDecorator}
            onChangeInn={onChangeInn}
            onChangeKpp={onChangeKpp}
            onChangeOgrn={onChangeOgrn}
          />
        </TabPane>
        <TabPane tab="Телефоны и почта" key="3">
          Телефоны и почта
        </TabPane>
        {isNew ? null : (
          <TabPane tab="Уровни приоритетов" key="4">
            <Priorities
              customerId={customer.id}
              priorities={customer.ticket_priorities}
              onChangeResponseTime={onChangeResponseTime}
              onChangeUseOpenHours={onChangeUseOpenHours}
              entityTicketPriorities={entityTicketPriorities}
              onChangeEntitiesForEntityTicketPriorities={onChangeEntitiesForEntityTicketPriorities}
              onChangeResponseTimeForEntityTicketPriorities={onChangeResponseTimeForEntityTicketPriorities}
              onChangeUseOpenHoursForEntityTicketPriorities={onChangeUseOpenHoursForEntityTicketPriorities}
            />
          </TabPane>
        )}
        {isNew ? null : (
          <TabPane tab="Настройка приоритетов заявок" key="6">
            <Timings
              getFieldDecorator={getFieldDecorator}
              customerId={customer.id}
              timings={timings}
              onCreateTiming={onCreateTiming}
              onEnableTiming={onEnableTiming}
              onChangeTimingFromState={onChangeTimingFromState}
              onChangeTimingToState={onChangeTimingToState}
              onChangeTimingPriority={onChangeTimingPriority}
              onChangeTimingEntity={onChangeTimingEntity}
              onChangeTimingDurationInMinutes={onChangeTimingDurationInMinutes}
              onDeleteTiming={onDeleteTiming}
            />
          </TabPane>
        )}
        {isNew ? null : (
          <TabPane tab="Настройки" key="5">
            <Settings
              getFieldDecorator={getFieldDecorator}
              customerId={customer ? customer.id : null}
              settings={customer.settings}
              onChangeTicketLogs={onChangeTicketLogs}
              onChangeTaskLogs={onChangeTaskLogs}
              onChangeIndoundEmails={onChangeIndoundEmails}
              onChangeInboundDomain={onChangeInboundDomain}
              onChangeInboundDomainTest={onChangeInboundDomainTest}
              onChangeInboundEmail={onChangeInboundEmail}
              onChangeInboundEmailTest={onChangeInboundEmailTest}
              onChangeInboundEmailMember={onChangeInboundEmailMember}
              onChangeUseAutoRate={onChangeUseAutoRate}
              onChangeAllowAllOrderTasks={onChangeAllowAllOrderTasks}
              onChangeCompletionCommentRequired={onChangeCompletionCommentRequired}
            />
          </TabPane>
        )}
      </Tabs>
    );
  }
}

General.propTypes = {
  customer: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
