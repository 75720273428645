import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  customers: [],
  customer: {},
  entityTicketPriorities: {
    entities: [],
    ticket_priorities: [
      { name: "низкий", sort: 1, response_time: 24, use_open_hours: true },
      { name: "средний", sort: 2, response_time: 24, use_open_hours: true },
      { name: "высокий", sort: 3, response_time: 8, use_open_hours: false },
      { name: "критичный", sort: 4, response_time: 3, use_open_hours: false }
    ],
  },
  entityIdsWithCustomTicketPriorities: [],
  customTicketPriorities: [],
  ticketActivityTimings: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isContactsLoading: false,
  isUploadingLogo: false,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_CUSTOMERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CUSTOMERS":
      return {
        ...state,
        customers: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_CUSTOMER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CUSTOMER":
      return {
        ...state,
        customer: action.response.data,
        isLoading: false,
      };
    case "PATCH_CUSTOMER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_CUSTOMER":
      return {
        ...state,
        customer: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_CUSTOMER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_CUSTOMER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_CUSTOMER":
      return {
        ...state,
        errors: false,
        customer: action.response.data,
        isLoading: false,
      };

    case "POST_CUSTOMER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_CUSTOMER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_CUSTOMER":
      return {
        ...state,
        customers: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_CUSTOMER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "CUSTOMER_NEW":
      return {
        ...state,
        errors: false,
        customer: {
          active: true,
          role: "manager",
        },
        isLoading: false,
      };

    case "REQUEST_CUSTOMER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });
    // pagination
    case "REQUEST_CUSTOMERS_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_CUSTOMERS_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    // general

    case "CUSTOMER_CHANGE_NAME":
      return update(state, {
        customer: { name: { $set: action.value } },
      });

    case "CUSTOMER_CHANGE_WEBSITE":
      return update(state, {
        customer: { name: { $set: action.value } },
      });

    case "CUSTOMER_CHANGE_POSTAL_CODE":
      return update(state, {
        customer: { postal_code: { $set: action.value } },
      });

    case "CUSTOMER_CHANGE_CITY":
      return update(state, {
        customer: { city: { $set: action.value } },
      });

    case "CUSTOMER_CHANGE_STREET":
      return update(state, {
        customer: { street: { $set: action.value } },
      });

    case "CUSTOMER_CHANGE_BUILDING":
      return update(state, {
        customer: { building: { $set: action.value } },
      });

    // contacts
    case "POST_CUSTOMER_CONTACT_REQUEST":
      return {
        ...state,
        isContactsLoading: true,
        errors: false,
      };

    case "POST_CUSTOMER_CONTACT_SUCCEED":
      return update(state, {
        customer: { contacts: { $set: action.response.data } },
        isContactsLoading: { $set: false },
      });

    case "POST_CUSTOMER_CONTACT_FAILURE":
      return {
        ...state,
        errors: true,
        isContactsLoading: false,
      };

    case "PATCH_CUSTOMER_CONTACT_REQUEST":
      return {
        ...state,
        isContactsLoading: true,
        errors: false,
      };

    case "PATCH_CUSTOMER_CONTACT_SUCCEED":
      return update(state, {
        customer: { contacts: { $set: action.response.data } },
        isContactsLoading: { $set: false },
      });

    case "PATCH_CUSTOMER_CONTACT_FAILURE":
      return {
        ...state,
        errors: true,
        isContactsLoading: false,
      };

    case "CUSTOMER_CONTACT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isContactsLoading: true,
      };

    case "CUSTOMER_CONTACT_DELETE_SUCCEED":
      return update(state, {
        customer: { contacts: { $set: action.response.data } },
        isContactsLoading: { $set: false },
      });

    case "CUSTOMER_CONTACT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isContactsLoading: false,
      };

    // logo
    case "CUSTOMER_LOGO_UPLOAD":
      return update(state, {
        isUploadingLogo: { $set: true },
      });

    case "CUSTOMER_LOGO_UPLOAD_SUCCEED":
      return update(state, {
        customer: { logo: { $set: action.response.data.logo } },
        errors: { $set: false },
        isUploadingLogo: { $set: false },
      });

    case "CUSTOMER_LOGO_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingLogo: { $set: false },
      });

    case "CUSTOMER_CHANGE_TICKET_PRIORITY_RESPONSE_TIME":
      idx = _.findIndex(state.customer.ticket_priorities, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        customer: {
          ticket_priorities: {
            [idx]: {
              response_time: { $set: action.value },
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_TICKET_PRIORITY_USE_OPEN_HOURS":
      idx = _.findIndex(state.customer.ticket_priorities, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        customer: {
          ticket_priorities: {
            [idx]: {
              use_open_hours: { $set: action.value },
            },
          },
        },
      });
    case "FETCH_ENTITY_TICKET_PRIORITIES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ENTITY_TICKET_PRIORITIES_SUCCESS":
      return {
        ...state,
        entityTicketPriorities: action.response,
        isLoading: false,
      };
    case "FETCH_ENTITY_TICKET_PRIORITIES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "CUSTOMER_CHANGE_ENTITIES_FOR_ENTITY_TICKET_PRIORITIES":
      return update(state, {
        entityTicketPriorities: {
          entities: { $set: action.values },
        },
      });
    case "CUSTOMER_CHANGE_RESPONSE_TIME_FOR_ENTITY_TICKET_PRIORITIES":
      idx = action.id;
      if (idx === -1) {
        return null;
      }

      return update(state, {
        entityTicketPriorities: {
          ticket_priorities: {
            [idx]: {
              response_time: { $set: action.value },
            },
          }
        },
      });
    case "CUSTOMER_CHANGE_USE_OPEN_HOURS_FOR_ENTITY_TICKET_PRIORITIES":
      idx = action.id;
      if (idx === -1) {
        return null;
      }

      return update(state, {
        entityTicketPriorities: {
          ticket_priorities: {
            [idx]: {
              use_open_hours: { $set: action.value },
            },
          }
        },
      });

    case "POST_CUSTOMER_ENTITY_TICKET_PRIORITIES_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "POST_CUSTOMER_ENTITY_TICKET_PRIORITIES_SUCCESS":
      return {
        ...state,
        // entityTicketPriorities: action.response,
        isLoading: false,
        errors: false,
      };
    case "POST_CUSTOMER_ENTITY_TICKET_PRIORITIES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    // timings
    case "FETCH_TICKET_ACTIVITY_TIMINGS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TICKET_ACTIVITY_TIMINGS_SUCCESS":
      return {
        ...state,
        ticketActivityTimings: action.response.data,
        isLoading: false,
      };
    case "FETCH_TICKET_ACTIVITY_TIMINGS_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    case "POST_TICKET_ACTIVITY_TIMING_SUCCESS":
      return {
        ...state,
        ticketActivityTimings: [...state.ticketActivityTimings, action.response.data]
      }
    case "POST_TICKET_ACTIVITY_TIMING_FAILURE":
      return {
        ...state,
        errors: true,
      };
    case "PATCH_TICKET_ACTIVITY_TIMING_SUCCESS":
      idx = _.findIndex(state.ticketActivityTimings, ["id", action.response.data.id]);
      if (idx === -1) {
        return null;
      }

      return update(state, {
        ticketActivityTimings: {
          [idx]: {
            $set: action.response.data,
          },
        },
      });
    case "PATCH_TICKET_ACTIVITY_TIMING_FAILURE":
      return {
        ...state,
        errors: true,
      };
    case "DELETE_TICKET_ACTIVITY_TIMING_SUCCESS":
      idx = _.findIndex(state.ticketActivityTimings, ["id", action.id]);
      if (idx === -1) {
        return null;
      }

      return {
        ...state,
        ticketActivityTimings: [
          ...state.ticketActivityTimings.slice(0, idx),
          ...state.ticketActivityTimings.slice(idx + 1)
        ],
      }
    case "DELETE_TICKET_ACTIVITY_TIMING_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // settings
    case "CUSTOMER_CHANGE_TICKET_LOGS":
      return update(state, {
        customer: {
          settings: {
            is_ticket_logs: {
              $set: !state.customer.settings.is_ticket_logs,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_TASK_LOGS":
      return update(state, {
        customer: {
          settings: {
            is_task_logs: {
              $set: !state.customer.settings.is_task_logs,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_INBOUND_EMAILS":
      return update(state, {
        customer: {
          settings: {
            is_inbound_emails: {
              $set: !state.customer.settings.is_inbound_emails,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_INBOUND_DOMAIN":
      return update(state, {
        customer: {
          settings: {
            inbound_domain: {
              $set: action.value,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_INBOUND_DOMAIN_TEST":
      return update(state, {
        customer: {
          settings: {
            inbound_domain_test: {
              $set: action.value,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_INBOUND_EMAIL":
      return update(state, {
        customer: {
          settings: {
            inbound_email: {
              $set: action.value,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_INBOUND_EMAIL_TEST":
      return update(state, {
        customer: {
          settings: {
            inbound_email_test: {
              $set: action.value,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_INBOUND_EMAIL_MEMBER":
      return update(state, {
        customer: {
          settings: {
            inbound_email_member: {
              $set: action.value,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_USE_AUTO_RATE":
      return update(state, {
        customer: {
          settings: {
            use_auto_rate: {
              $set: action.value,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_ALLOW_ALL_ORDER_TASKS":
      return update(state, {
        customer: {
          settings: {
            allow_all_order_tasks: {
              $set: action.value,
            },
          },
        },
      });

    case "CUSTOMER_CHANGE_COMPLETION_COMMENT_REQUIRED":
      return update(state, {
        customer: {
          settings: {
            completion_comment_required: {
              $set: action.value,
            },
          },
        },
      });

    default:
      return state;
  }
}
