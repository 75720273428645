// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Badge, Button, Card, Col, Dropdown, Form, Menu, Row, Tabs,} from "antd";
import Statuses from "./Statuses";
import Sidebar from "./Sidebar";
import Activities from "./Activities";
import WorkTime from "./WorkTime";
import Comments from "./Comments";
import General from "./General";
import GeneralInfo from "./GeneralInfo";
import moment from "moment";
import "moment/locale/ru";
import Can from "../../../rules/Can";
import Tasks from "../../../components/Tasks";
import Documents from "../Documents";
import CommentForm from "./CommentForm";

const { TabPane } = Tabs;

class TicketForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      commentFailureReason: '',
      commentFixDescription: '',
      status: null,
      isCommentFormVisible: false,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  handleMenuClick = (e) => {
    console.log("click", e);
  };

  callback = (key) => {
    if (key == "2") {
      this.props.fetchRequestForTicketActivities();
    }

    if (key == "3") {
      this.props.fetchTicketWorkTime();
    }

    if (key == "4") {
      this.props.fetchTasks();
    }
  };

  // actions
  onAction = (actionId) => {
    const { ticket, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], ticket.id);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  // status
  onChangeStatus = (value) => {
    const {
      ticket,
    } = this.props;

    const customerSettings = ticket?.customer?.settings;

    if (value.id === 'closed' && customerSettings?.completion_comment_required) {
      this.setState({
        isCommentFormVisible: true,
        status: value,
      });
    } else {
      this.props.onChangeStatus(value, null);
    }
  };

  // comment
  onSaveComment = () => {
    const {
      status,
      commentFailureReason,
      commentFixDescription,
    } = this.state;

    const comment = `${commentFailureReason}<br/>${commentFixDescription}`

    this.props.onChangeStatus(status, comment);

    this.setState({
      isCommentFormVisible: false,
      status: null,
    });
  };

  onCancelComment = () => {
    this.setState({
        commentFailureReason: '',
        commentFixDescription: '',
      },
      () => {
        this.setState({
            isCommentFormVisible: false,
            status: null
          },
          () => {
            this.props.fetchTicket();
          });
      });
  };

  onChangeComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  onChangeCommentFailureReason = (e) => {
    this.setState({
      commentFailureReason: e.target.value,
    });
  };

  onChangeCommentFixDescription = (e) => {
    this.setState({
      commentFixDescription: e.target.value,
    });
  };

  render() {
    const {
      isCommentFormVisible,
      commentFailureReason,
      commentFixDescription,
    } = this.state;

    const {
      isNew,
      forTaskId,
      isLoading,
      currentUser,
      isCommentsLoading,
      isUploading,
      isAssigneesLoading,
      errors,
      ticket,
      onClose,
      onChangeNumber,
      onChangeCustomer,
      onChangeEntity,
      onChangeEntityService,
      onChangeCategory,
      onChangeServices,
      onChangeStatus,
      onChangePiority,
      onChangeIncident,
      onChangeAmount,
      onChangeSubject,
      onChangeDescription,
      onChangeDueBy,
      onCreateComment,
      onDeleteAttachment,
      onUploadAttachment,
      onUploadCommentAttachment,
      onDeleteCommentAttachment,
      // preUpload
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
      // assignees
      onCreateAssignee,
      onDeleteAssignee,
      onChangeReplaced,
      onChangeOrdered,
      onChangeOrder,
      onChangeIsOrganisation,
      onChangeIsSupplier,
      onChangeCompany,
      ticketActivities,
      ticketActivityOverdues,
      isActivitiesLoading,
      // tasks
      isTasksLoading,
      tasks,
      // ticket_work_times
      ticketWorkTimes,
      isWorkTimesLoading,
      onCreateWorkTime,
      onUpdateWorkTime,
      // actions
      actions,
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        style={{ marginRight: "8px" }}
        onClick={this.props.onFaqToggle}
      />
    );

    const organisationAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const supervisorAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "supervisor";
    });

    const customerAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const commonAttachments = ticket.attachments.filter(function (item) {
      return item.attachment_type == 1;
    });

    const actAttachments = ticket.attachments.filter(function (item) {
      return item.attachment_type == 2;
    });

    const commentAttachments = (ticket.attachments ?? []).filter((attachment) => !!attachment.ticket_comment_id);

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }}/>
        </Dropdown>
      );
    }

    let ticketNumber = isNew ?
      forTaskId === null ? "новая" : `новая (для задачи #${forTaskId})` :
      ticket.number;
    let ticketDate = ticket.created_at === null ?
      moment().format("LLL") :
      moment(ticket.created_at).format("LLL")

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2 style={{ marginBottom: 0 }}>
                {`Заявка #${ticketNumber} от ${ticketDate}`}
              </h2>
            </div>
          }
          extra={[
            faqButton,
            <Button key={"1"}
                    onClick={onClose}>Закрыть</Button>,
            <Button key={"2"}
                    onClick={this.onSubmit}
                    type="primary"
                    ghost
                    style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>,
            actionsButton,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Can
                role={currentUser.role}
                perform="tickets:edit"
                yes={() => (
                  <General
                    currentUser={currentUser}
                    isNew={isNew}
                    getFieldDecorator={getFieldDecorator}
                    onChangeCustomer={onChangeCustomer}
                    onChangeCompany={onChangeCompany}
                    onChangeEntity={onChangeEntity}
                    onChangeEntityService={onChangeEntityService}
                    onChangeCategory={onChangeCategory}
                    onChangeServices={onChangeServices}
                    onChangeSubject={onChangeSubject}
                    onChangeDescription={onChangeDescription}
                    onChangeDueBy={onChangeDueBy}
                    ticket={ticket}
                  />
                )}
                no={() => <GeneralInfo ticket={ticket}/>}
              />
            </Row>

            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {isNew ? null : (
                  <Tabs defaultActiveKey="7" onChange={this.callback}>
                    <TabPane tab="Комментарии" key="7">
                      <Comments
                        currentUser={currentUser}
                        getFieldDecorator={getFieldDecorator}
                        isCommentsLoading={isCommentsLoading}
                        errors={errors}
                        onCreateComment={onCreateComment}
                        customer={ticket.customer}
                        comments={ticket.comments}
                        commentAttachments={commentAttachments}
                        onUploadCommentAttachment={onUploadCommentAttachment}
                        onDeleteCommentAttachment={onDeleteCommentAttachment}
                      />
                    </TabPane>
                    <TabPane tab="Файлы" key="8">
                      <Row>
                        <Documents
                          currentUser={currentUser}
                          title={""}
                          isUploading={isUploading}
                          attachments={commonAttachments}
                          onUpload={onUploadAttachment}
                          onDelete={onDeleteAttachment}
                        />
                      </Row>
                    </TabPane>
                  </Tabs>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1}/>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Card style={{ borderColor: "#1890ff" }} loading={isLoading}>
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Статусы" key="1">
                  <Can
                    role={currentUser.role}
                    perform="tickets:edit"
                    yes={() => (
                      <Statuses
                        isNew={isNew}
                        currentUser={currentUser}
                        ticketId={ticket.id}
                        isLoading={isLoading}
                        isUploading={isUploading}
                        getFieldDecorator={getFieldDecorator}
                        isReplaced={ticket.is_replaced}
                        isTicketReplaceAvailable={
                          ticket.is_ticket_replace_available
                        }
                        isTicketAmountAvailable={
                          ticket.is_ticket_amount_available
                        }
                        isOrdered={ticket.is_ordered}
                        isOrganisation={ticket.is_organisation}
                        isSupplier={ticket.is_supplier}
                        dueBy={ticket.due_by}
                        user={ticket.user}
                        member={ticket.member}
                        attachments={actAttachments}
                        status={ticket.status}
                        priority={ticket.priority}
                        ticketPriority={ticket.ticket_priority}
                        incident={ticket.incident}
                        amount={ticket.amount}
                        number={ticket.number}
                        order={ticket.order}
                        onChangeNumber={onChangeNumber}
                        onChangeStatus={this.onChangeStatus}
                        onChangePiority={onChangePiority}
                        onChangeReplaced={onChangeReplaced}
                        onChangeOrdered={onChangeOrdered}
                        onChangeOrder={onChangeOrder}
                        onChangeIsOrganisation={onChangeIsOrganisation}
                        onChangeIsSupplier={onChangeIsSupplier}
                        onChangeIncident={onChangeIncident}
                        onChangeAmount={onChangeAmount}
                        onDeleteAttachment={onDeleteAttachment}
                        onUploadAttachment={onUploadAttachment}
                        // preUpload
                        tempAttachments={tempAttachments}
                        onTempUpload={onTempUpload}
                        onDeleteTempAttachment={onDeleteTempAttachment}
                        // assignees
                        organisationAssignees={organisationAssignees}
                        supervisorAssignees={supervisorAssignees}
                        customer={ticket.customer}
                        customerAssignees={customerAssignees}
                        companyAssignees={companyAssignees}
                        isAssigneesLoading={isAssigneesLoading}
                        onDeleteAssignee={onDeleteAssignee}
                        onCreateAssignee={onCreateAssignee}
                      />
                    )}
                    no={() => (
                      <Sidebar
                        isNew={isNew}
                        currentUser={currentUser}
                        ticketId={ticket.id}
                        isLoading={isLoading}
                        isUploading={isUploading}
                        getFieldDecorator={getFieldDecorator}
                        isReplaced={ticket.is_replaced}
                        isTicketReplaceAvailable={
                          ticket.is_ticket_replace_available
                        }
                        isTicketAmountAvailable={
                          ticket.is_ticket_amount_available
                        }
                        isOrdered={ticket.is_ordered}
                        isOrganisation={ticket.is_organisation}
                        isSupplier={ticket.is_supplier}
                        dueBy={ticket.due_by}
                        user={ticket.user}
                        member={ticket.member}
                        attachments={actAttachments}
                        status={ticket.status}
                        replaced_status={ticket.replaced_status}
                        priority={ticket.priority}
                        ticketPriority={ticket.ticket_priority}
                        incident={ticket.incident}
                        amount={ticket.amount}
                        number={ticket.number}
                        onChangeStatus={this.onChangeStatus}
                        onChangeReplaced={onChangeReplaced}
                        onChangePiority={onChangePiority}
                        onChangeIncident={onChangeIncident}
                        onChangeAmount={onChangeAmount}
                        onDeleteAttachment={onDeleteAttachment}
                        onUploadAttachment={onUploadAttachment}
                        // preUpload
                        tempAttachments={tempAttachments}
                        onTempUpload={onTempUpload}
                        onDeleteTempAttachment={onDeleteTempAttachment}
                        // assignees
                        organisationAssignees={organisationAssignees}
                        supervisorAssignees={supervisorAssignees}
                        customer={ticket.customer}
                        customerAssignees={customerAssignees}
                        company={ticket.company}
                        companyAssignees={companyAssignees}
                      />
                    )}
                  />
                </TabPane>
                {isNew ? null : (
                  <TabPane tab="Время выполнения" key="3">
                    <WorkTime
                      isReplaced={ticket.is_replaced}
                      status={ticket.status}
                      ticketWorkTimes={ticketWorkTimes}
                      isLoading={isWorkTimesLoading}
                      onCreateWorkTime={onCreateWorkTime}
                      onUpdateWorkTime={onUpdateWorkTime}
                    />
                  </TabPane>
                )}
                {isNew ? null : (
                  <TabPane tab="Задачи" key="4">
                    <Tasks isLoading={isTasksLoading} tasks={tasks}/>
                  </TabPane>
                )}
                {isNew ? null : (
                  <TabPane key="2"
                           tab={
                             <Badge style={{ backgroundColor: '#ff6600'}}
                                    count={ticketActivityOverdues.length}>
                               Лог изменений
                             </Badge>}>
                    <Activities
                      ticketActivities={ticketActivities}
                      ticketActivityOverdues={ticketActivityOverdues}
                      isActivitiesLoading={isActivitiesLoading}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Card>
          </Col>
        </Card>
        <CommentForm
          visible={isCommentFormVisible}
          commentFailureReason={commentFailureReason}
          commentFixDescription={commentFixDescription}
          onSave={this.onSaveComment}
          onCancel={this.onCancelComment}
          onChangeCommentFailureReason={this.onChangeCommentFailureReason}
          onChangeCommentFixDescription={this.onChangeCommentFixDescription}
        />
      </Form>
    );
  }
}

TicketForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  ticket: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(TicketForm);
