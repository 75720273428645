// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/tickets";
import TaskActions from "../../../actions/tasks";
import FaqActions from "../../../actions/faqs";
import Form from "./Form";
import LoadingSpin from "../../../components/LoadingSpin";
import moment from "moment";
import "moment/locale/ru";
import {encodeBase64} from "../../../util/attachments";


class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      forTaskId: null,
      tempAttachments: [],
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props
        .dispatch(Actions.fetchTicket(this.props.match.params.id))
        .then(() => {
          this.props.dispatch(
            TaskActions.fetchTaskActions({taskOwners: [4]})
          );
        });
      this.props.dispatch(Actions.fetchTicketActivityOverdues(this.props.match.params.id));
    } else {
      let forTaskId = this.props.location.state?.forTaskId ?? null
      if (forTaskId === null) {
        this.setState({isNew: true}, () => {
          this.props.dispatch(Actions.onNew());
        });
      } else {
        this.setState({isNew: true, forTaskId: forTaskId}, () => {
          this.props.dispatch(Actions.onNewForTask(forTaskId));
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (this.props.match.params.id) {
        this.setState({isNew: false}, () => {
          this.props.dispatch(Actions.fetchTicket(this.props.match.params.id));
        });
      } else {
        this.setState({isNew: true});
      }
    }
  }

  fetchTicket = () => {
    this.props.dispatch(Actions.fetchTicket(this.props.match.params.id));
  };

  onSave = () => {
    const {tempAttachments} = this.state;
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      let forTaskId = this.props.location.state?.forTaskId ?? null
      if (forTaskId === null) {
        this.props.dispatch(Actions.onCreate(tempAttachments)).then(() => {
          if (!this.props.errors) {
            this.setState({isNew: false}, () => {
              this.props.history.push(`/tickets`);
            });
          }
        });
      } else {
        this.props.dispatch(Actions.onCreateForTask(forTaskId, tempAttachments)).then(() => {
          if (!this.props.errors) {
            this.setState({isNew: false}, () => {
              this.props.history.push(`/tickets`);
            });
          }
        });
      }
    }
  };

  onClose = () => {
    this.props.history.push(`/tickets`);
  };

  onChangeNumber = (e) => {
    this.props.dispatch(Actions.onChangeNumber(e.target.value));
  };

  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  onChangeEntity = (value) => {
    this.props.dispatch(Actions.onChangeEntity(value));
  };

  onChangeEntityService = (value) => {
    this.props.dispatch(Actions.onChangeEntityService(value));
  };

  onChangeCategory = (value) => {
    this.props.dispatch(Actions.onChangeCategory(value));
  };

  onChangeServices = (values) => {
    this.props.dispatch(Actions.onChangeServices(values));
  };

  onChangePiority = (value) => {
    this.props.dispatch(Actions.onChangePiority(value));
  };

  onChangeIncident = (value) => {
    this.props.dispatch(Actions.onChangeIncident(value));
  };

  onChangeAmount = (value) => {
    this.props.dispatch(Actions.onChangeAmount(value));
  };

  onChangeSubject = (e) => {
    this.props.dispatch(Actions.onChangeSubject(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeDueBy = (value) => {
    this.props.dispatch(Actions.onChangeDueBy(value));
  };

  onChangeReplaced = (e) => {
    this.props.dispatch(
      Actions.onChangeReplaced(this.props.match.params.id, e.target.checked)
    ).then(() => this.props.dispatch(Actions.fetchTicket(this.props.match.params.id)));
  };

  onChangeOrdered = (e) => {
    this.props.dispatch(
      Actions.onChangeOrdered(this.props.match.params.id, e.target.checked)
    );
  };

  onChangeOrder = (value) => {
    this.props.dispatch(Actions.onChangeOrder(value));
  };

  onChangeIsOrganisation = (e) => {
    this.props.dispatch(Actions.onChangeIsOrganisation(e.target.checked));
  };

  onChangeIsSupplier = (e) => {
    this.props.dispatch(Actions.onChangeIsSupplier(e.target.checked));
  };

  onChangeCompany = (value) => {
    this.props.dispatch(Actions.onChangeCompany(value));
  };

  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchTicketComments(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadCommentAttachment = (commentId, file) => {
    encodeBase64(file, (fileUrl) =>
      this.props
        .dispatch(Actions.onUploadAttachment(
          this.props.match.params.id,
          {
            id: file.uid,
            name: file.name,
            content_type: file.type,
            attachment_type: 1,
            file_size: file.size,
            attachment: fileUrl,
          },
          commentId))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchAttachments(this.props.match.params.id)
            );
          }
        })
    );
  };

  // preUploads
  onTempUpload = (attachment) => {
    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $push: [attachment],
      }),
    });
  };

  onDeleteTempAttachment = (id) => {
    let idx = this.state.tempAttachments.findIndex(function (o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $splice: [[idx, 1]],
      }),
    });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // statuses
  onChangeStatus = (value, comment) => {
    this.props
      .dispatch(
        Actions.onChangeStatus(this.props.match.params.id, value.id, comment)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchTicketComments(this.props.match.params.id)
          );
        }
      });
  };

  // activities
  fetchRequestForTicketActivities = () => {
    this.props.dispatch(Actions.fetchRequestForTicketActivities(this.props.match.params.id));
  };

  // tasks
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks(this.props.match.params.id));
  };

  // ticket_work_times
  fetchTicketWorkTime = () => {
    this.props.dispatch(
      Actions.fetchTicketWorkTime(this.props.match.params.id)
    );
  };

  onCreateWorkTime = () => {
    const time = moment();
    this.props.dispatch(
      Actions.onCreateWorkTime(this.props.match.params.id, time)
    );
  };

  onUpdateWorkTime = () => {
    const {ticketWorkTimes} = this.props;
    if (ticketWorkTimes.length > 0) {
      const time = moment();
      this.props.dispatch(
        Actions.onUpdateWorkTime(
          this.props.match.params.id,
          ticketWorkTimes[0].id,
          time
        )
      );
    }
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(2, 2));
    });
  };

  // actions
  onOpenTaskForm = (action, id) => {
    const {ticket} = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, ticket.id));
  };

  render() {
    const {tempAttachments} = this.state;
    const {
      errors,
      currentUser,
      isLoading,
      isCommentsLoading,
      isUploading,
      isDefectActUploading,
      isAssigneesLoading,
      ticket,
      ticketActivities,
      ticketActivityOverdues,
      // tasks
      isTasksLoading,
      tasks,
      isActivitiesLoading,
      // ticket_work_times
      ticketWorkTimes,
      isWorkTimesLoading,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;

    return isLoading || errors ? (
      <LoadingSpin/>
    ) : (
      <Form
        currentUser={currentUser}
        isNew={this.state.isNew}
        forTaskId={this.state.forTaskId}
        isLoading={isLoading}
        isUploading={isUploading}
        isDefectActUploading={isDefectActUploading}
        isCommentsLoading={isCommentsLoading}
        isAssigneesLoading={isAssigneesLoading}
        isActivitiesLoading={isActivitiesLoading}
        errors={errors}
        ticket={ticket}
        ticketActivities={ticketActivities}
        ticketActivityOverdues={ticketActivityOverdues}
        fetchTicket={this.fetchTicket}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeNumber={this.onChangeNumber}
        onChangeCustomer={this.onChangeCustomer}
        onChangeEntity={this.onChangeEntity}
        onChangeEntityService={this.onChangeEntityService}
        onChangeCategory={this.onChangeCategory}
        onChangeServices={this.onChangeServices}
        onChangePiority={this.onChangePiority}
        onChangeIncident={this.onChangeIncident}
        onChangeAmount={this.onChangeAmount}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangeDueBy={this.onChangeDueBy}
        onCreateComment={this.onCreateComment}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        onUploadCommentAttachment={this.onUploadCommentAttachment}
        onDeleteCommentAttachment={this.onDeleteAttachment}
        // preUpload
        tempAttachments={tempAttachments}
        onTempUpload={this.onTempUpload}
        onDeleteTempAttachment={this.onDeleteTempAttachment}
        // assignees
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // tasks
        isTasksLoading={isTasksLoading}
        tasks={tasks}
        fetchTasks={this.fetchTasks}
        // statuses
        onChangeStatus={this.onChangeStatus}
        onChangeReplaced={this.onChangeReplaced}
        onChangeOrdered={this.onChangeOrdered}
        onChangeOrder={this.onChangeOrder}
        onChangeIsOrganisation={this.onChangeIsOrganisation}
        onChangeIsSupplier={this.onChangeIsSupplier}
        onChangeCompany={this.onChangeCompany}
        fetchRequestForTicketActivities={this.fetchRequestForTicketActivities}
        // ticket_work_times
        ticketWorkTimes={ticketWorkTimes}
        isWorkTimesLoading={isWorkTimesLoading}
        fetchTicketWorkTime={this.fetchTicketWorkTime}
        onCreateWorkTime={this.onCreateWorkTime}
        onUpdateWorkTime={this.onUpdateWorkTime}
        // faqs
        onFaqToggle={this.onFaqToggle}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

Ticket.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  ticket: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.tickets.isLoading,
  isCommentsLoading: state.tickets.isCommentsLoading,
  isUploading: state.tickets.isUploading,
  isDefectActUploading: state.tickets.isDefectActUploading,
  isAssigneesLoading: state.tickets.isAssigneesLoading,
  errors: state.tickets.errors,
  ticket: state.tickets.ticket,
  ticketActivities: state.tickets.ticket_activities,
  ticketActivityOverdues: state.tickets.ticketActivityOverdues,
  isActivitiesLoading: state.tickets.isActivitiesLoading,
  // tasks
  isTasksLoading: state.tickets.isTasksLoading,
  tasks: state.tickets.tasks,
  // ticket_work_times
  ticketWorkTimes: state.tickets.ticket_work_times,
  isWorkTimesLoading: state.tickets.isWorkTimesLoading,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(Ticket));
